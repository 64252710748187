<template >
    <div class="body_box">
        <div class="box_1">
            <div class="box_2">
                <p class="hello">你好 !</p>
                <div class="retract"
                    >贵司 【{{ res.lesseeCompany }}】 的缴费账单已生成
                    ,查看账单详情并缴费。
                </div>
            </div>

            <!-- 账单信息 -->
            <div class="box_bill">
                <div class="module">
                    <i class="module_box">账单生成时间</i>
                    <span>{{ res.paymentDate }}</span>
                </div>
                <div class="module">
                    <i class="module_box">合同编号</i>
                    <span class="LeftMargin1">{{ res.contractNo }}</span>
                </div>
                <div class="module">
                    <i class="module_box">租客</i>
                    <span class="LeftMargin2">{{ res.lesseeName }}</span
                    ><br />
                    <span class="EdgeDistance">{{ res.lesseePhone }} </span>
                </div>
                <div class="module">
                    <i class="module_box1">房间</i>
                    <div class="roomS">
                        <span
                            class="LeftMargin3"
                            v-for="(house, indexId) in roomsinfo"
                            :key="indexId"
                            >{{ house.buildingName }} {{ house.roomName }}</span
                        >
                        <!-- <span class="EdgeDistance"></span> -->
                    </div>
                </div>
            </div>

            <!-- 应付金额 -->
            <div class="payment"
              v-for="(cost, index) in consumption"
              :key="`${cost.name}_${index}`"
            >
                <div class="payment_1">
                    <!-- 表格 -->
                    <div
                        class="slide"
                    >
                        <div class="topPadding">
                            <i>类目</i>
                            <span class="leftTwo">
                              {{ cost.name }}
                            </span>
                        </div>
                        <div>
                            <i>应付金额</i>
                            <span class="leftFour">
                              {{ cost.payableAmount }}元
                            </span>
                        </div>
                        <div>
                            <i>抵扣金额</i>
                            <span class="leftFour">
                              {{ cost.deductionAmount}}元
                            </span>
                        </div>
                        <div>
                            <i>实际应付金额</i>
                            <span class="leftSix">
                              {{ cost.paidAmount }}元
                            </span>
                        </div>
                        <div>
                            <i>抵扣原因</i>
                            <span class="leftFour">
                              {{ cost.comment || ' -' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 支付方式 -->
            <!-- <div class="PayWay">
        <div class="PayWay1"><i class="icon"></i><span>支付方式</span></div>
        <div class="PayWay2">
           <i class="icon_red "   ></i>
          <h3 class="Inline_block">微信支付</h3>  -->

            <!-- <el-radio v-model="radio" label="1" class="Inline_block1"></el-radio> -->
            <!-- </div>
        <div class="PayWay3">
           <i class="icon_radio1 "  ></i>
          <h3 class="Inline_block">支付宝</h3> -->
            <!-- <el-radio v-model="radio" label="2" class="Inline_block2"></el-radio> -->
            <!-- </div>
      </div> -->

            <!-- 支付按钮 -->
            <!-- <button class="Paybutton">立即支付</button> -->
        </div>
    </div>
</template>

<script>
// 导入请求接口
import { billUrl } from "@/requestUrl";

export default {
    name: "shortNoteBill",
    props: {
        keyk: String,
        default:''
    },
    data() {
        return {
              keyd:"",
            // 选中状态 默认是0   怒选中是1
            radio: 0,
            // 账单返回的数据
            res: {},
            // 房间信息
            roomsinfo: [],
            // 消费明细
            consumption: [],
        };
    },
    methods: {
        // 获取账单详情
        billParticulars() {
            this.$fly.get(billUrl + "?key=" + this.keyk).then((res) => {
                this.res = res.data.billData;
                this.consumption = res.data.installments;
                this.roomsinfo = res.data.billData.rooms;
                // console.log(this.res);
                console.log(this.roomsinfo);
            });
        },
    },
    created() {
        // if(this.keyk == ""){
        //     this.keyd = this.$route.query.key;
        // }
        this.billParticulars();
    }
};
</script>

<style lang="css" scoped>
/* * {
  padding: 0 !important;
  margin: 0 !important;
} */
/* 主体背景 */
.body_box {
    background-color: #f0f0f0;
}

.body_box .wrapper-content {
    padding: 0;
}

i {
    font-style: normal;
}
.box_1 {
    width: 100vw;
    height: 14vh;
    background-color: #fff;
    font-size: 4vw;
}
.box_2 {
    width: 100vw;
    height: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 0 4.5vw;
    margin-bottom: 2vw;
}
.box_2 .hello {
    padding-top: 3vw;
    font-size: 4vw;
    color: #000;
}
.retract {
    text-indent: 2em;
    color: #000;
}
.box_2 span {
    margin-top: 0;
    padding-top: 0;
}
/* 账单信息模块样式 */
.box_bill {
    width: 100vw;
    /* height: 27.8vh; */
    margin-top: 8vw;
    background-color: #fff;
    padding: 1vw 4.5vw 2vw 4.5vw;
    color: #000;
}
.module {
    margin: 3vw 0 0 0;
}
.module i {
    width: 27.7vw;
    margin-top: 3.3vw;
    margin-right: 5vw;
}
.LeftMargin1 {
    margin-left: 8.5vw;
}
.LeftMargin2 {
    margin-left: 15.5vw;
}
.LeftMargin3 {
    margin-left: 5.5vw;
    display: block;
    margin-bottom: 2vw;
}
.EdgeDistance {
    margin-left: 28.5vw;
}

/* 应付金额模块样式 */
.payment {
    width: 100vw;
    /* height: 25vh; */
    background-color: #fff;
    padding-left: 3vw;
    padding: 1.5vw 4.5vw 4vw 4.5vw;
    margin-top: 3vw;
}
.payment_1 {
    color: #000;
}
.slide div {
    margin: 2.5vw 0 0 0;
}
.payment span {
    margin-top: 3vw;
}

.Left_Margin {
    margin-left: 11vw;
}

/* 支付方式 */
.PayWay {
    width: 100vw;
    height: 19vh;
    margin: 3vw 0;
    padding-left: 3vw;
    background-color: #fff;
}

.icon {
    display: inline-block;
    width: 1.5vw;
    height: 2.5vh;
    background-color: #ff5a5a;
    margin-top: 1vw;
}
.PayWay1 span {
    display: inline-block;
    text-align: center;
    margin: 0 2vw;
}

/* 支付按钮模块样式 */
.Paybutton {
    width: 68vw;
    height: 8.2vh;
    background-color: #ff5a5a;
    border-radius: 3vw;
    border: 0;
    margin-left: 13vw;
    color: #ffffff;
    font-size: 6vw;
}

.Inline_block {
    display: inline-block;
}

.PayWay1 {
    padding-top: 3vw;
}
.PayWay2 {
    margin: 2vw 0;
    position: relative;
    border-bottom: 1vw #cccccc solid;
}
.PayWay3 {
    position: relative;
}
.Inline_block1 {
    display: inline-block;
    margin-left: 68vw;
}

.Inline_block2 {
    display: inline-block;
    margin-left: 72.5vw;
}
/* 微信支付按钮 */
.icon_red {
    display: inline-block;
    position: absolute;
    top: 1vw;
    right: 6vw;
    width: 7vw;
    height: 4vh;
    background: url(../assets/icon1.png) no-repeat;
    background-size: 5.95vw;
    z-index: 5;
}
/* 失去焦点后的状态 */
.icon_uncheck {
    display: none;
    display: inline-block;
    position: absolute;
    top: 1vw;
    right: 6vw;
    width: 7vw;
    height: 4vh;
    background: url(../assets/icon2.png) no-repeat;
    background-size: 5.95vw;
    z-index: 5;
}
/* 支付宝支付按钮 */
.icon_radio1 {
    display: inline-block;
    position: absolute;
    top: 1.6vw;
    right: 5.5vw;
    width: 7vw;
    height: 4vh;
    background: url(../assets/icon2.png) no-repeat;
    background-size: 5vw;
}
/* 点击后选中的状态 */
.icon_check {
    display: inline-block;
    /* display: none; */
    position: absolute;
    top: 1.6vw;
    right: 5.5vw;
    width: 7vw;
    height: 4vh;
    background: url(../assets/icon1.png) no-repeat;
    background-size: 5vw;
}

.module div {
    display: inline-block;
}
.roomS {
    display: inline-block;
    margin-left: 10vw;
}

.leftFour {
    margin-left: 11vw;
    color: #000;
}
.leftSix {
    margin-left: 3.2vw;
    color: #000;
}
.leftTwo {
    margin-left: 19vw;
    color: #000;
}
.module_box1 {
    text-align: left;
    vertical-align: top;
}
</style>
